.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(52 , 58 , 64);
}

.spinner {
    margin: 0 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #7e7c7c;
    border-top: 4px solid white;
    animation: rotating-spinner 1s linear infinite;
}

@keyframes rotating-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}